<template>
  <Header />
  <section class="training-list">
    <h1 class="title">Capacitaciones</h1>
    <div class="training-grid">
      <div
        v-for="training in trainings"
        :key="training.id"
        class="training-card"
        :style="training.estado === 'Visto' ? vistoStyles : noVistoStyles"
      >
        <div class="status">
          <span class="status-text" :style="training.estado === 'Visto' ? revisadoStyles : {}">
            {{ training.estado === 'Visto' ? '✔ Revisado' : 'Sin Revisar' }}
          </span>
        </div>

        <div class="training-name">
          <h3>{{ training.nombre }}</h3>
        </div>

        <div class="action">
          <button @click="goToTraining(training.id)" class="action-button">Ver ahora</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from '../common/Header.vue';
import axios from 'axios';

export default {
  components: { Header },
  data() {
    return {
      trainings: [],
      vistoStyles: {
        border: '2px solid #009ca6',
      },
      noVistoStyles: {
        border: '2px solid red',
      },
      revisadoStyles: {
        color: '#009ca6',
      },
    };
  },
  async created() {
    try {
      const userId = this.$store.getters.currentUser.id;
      const response = await axios.get(`/api/trainings/status/${userId}`);
      //this.trainings = response.data;
      //ocultar capacitaciones
      this.trainings = response.data.filter(training => training.id !== 9);
      this.trainings = response.data.filter(training => training.id !== 12);
    } catch (error) {
      console.error('Error al obtener capacitaciones', error);
    }
  },
  methods: {
    goToTraining(id) {
      this.$router.push(`/trainings/${id}`);
    },
  },
};
</script>


<style scoped>
.training-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  background-color: #F2F2F2;
}

.title {
  font-family: 'ColgateReady-Regular';
  font-size: 2.4em;
  color: #d32f2f;
  margin-bottom: 1em;
}

.training-grid {
  display: grid;
  gap: 1em;
  width: 100%;
  max-width: 1391px; 
}

.training-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3em 2em;
  border: 2px solid #d32f2f;
  border-radius: 1em;
  background-color: #ffffff;
  width: 100%;
  max-width: 1391px;
  height: 145px;
  box-sizing: border-box;
}

.status {
  display: flex;
  align-items: center;
  color: #d32f2f;
}

.status-text {
  font-family: 'ColgateReady-Regular';
  font-size: 1em;
  font-weight: bold;
}

.training-name {
  flex-grow: 1;
  text-align: center;
  font-family: 'ColgateReady-Italic';
  font-size: 1.2em;
  color: #d32f2f;
  font-weight: bold;
}

.action {
  display: flex;
  align-items: center;
}

.action-button {
  background-color: #d32f2f;
  color: #ffffff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 35px;
  font-family: 'ColgateReady-Regular';
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
}

.action-button:hover {
  background-color: #b71c1c;
}

/* Responsivo */
@media (max-width: 768px) {
  .training-card {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 1em 0.5em;
  }

  .training-name {
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .action-button {
    width: 100%;
    text-align: center;
    padding: 0.7em;
  }
}
</style>
