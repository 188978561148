import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import './assets/styles/main.css';
import './assets/styles/fonts.css';
import axios from 'axios';

// configurcion de la URL base para Axios
axios.defaults.baseURL = 'https://crecerconcolgate.col1.co/'; // http://localhost:3000 - https://crecerconcolgate.col1.co/
createApp(App).use(router).use(store).mount('#app');
